import { tokio404, instagram, face, linked, twiter, youtube } from "../assets";
import DefaultLayout from "../shared/layouts/DefaultLayout";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <DefaultLayout>
      <div className="flex h-screen flex-col items-center gap-10">
        <div className="flex w-full flex-col items-center justify-start gap-6 px-5 pt-10">
          <img
            src={tokio404}
            alt="not-found-illustration-pacificode"
            className="w-full md:w-1/3 xmd:w-1/3 lg:w-1/3"
          />
          <h1 className="text-center font-poppinsBold text-4xl">
            ¡Ups! No encontramos la página
          </h1>
          <div className="mt-6">
            <PrincipalButton
              label="Regresar a la página principal"
              handleAction={() => {
                navigate("/");
              }}
              classPadding="px-5 py-2"
              width="w-full"
            />
          </div>
        </div>
        <div className="flex w-full flex-row justify-center gap-5 md:w-1/2 xmd:w-1/3 lg:w-1/4">
          <button className="relative flex max-h-16 max-w-16 items-center justify-center rounded-3xl p-2 md:p-2 xmd:p-2 lg:p-2">
            <div className="absolute h-full w-full rounded-3xl bg-primary p-2 opacity-15 md:p-2 xmd:p-2 lg:p-4"></div>
            <img
              className="object-scale-down"
              src={instagram}
              alt="instagram"
            />
          </button>
          <button className="relative flex max-h-16 max-w-16 items-center justify-center rounded-3xl p-2 md:p-2 xmd:p-2 lg:p-2">
            <div className="absolute h-full w-full rounded-3xl bg-primary p-2 opacity-15 md:p-2 xmd:p-2 lg:p-4"></div>
            <img className="object-scale-down" src={face} alt="face" />
          </button>
          <button className="relative flex max-h-16 max-w-16 items-center justify-center rounded-3xl p-2 md:p-2 xmd:p-2 lg:p-2">
            <div className="absolute h-full w-full rounded-3xl bg-primary p-2 opacity-15 md:p-2 xmd:p-2 lg:p-4"></div>
            <img className="object-scale-down" src={linked} alt="linked" />
          </button>
          <button className="relative flex max-h-16 max-w-16 items-center justify-center rounded-3xl p-2 md:p-2 xmd:p-2 lg:p-2">
            <div className="absolute h-full w-full rounded-3xl bg-primary p-2 opacity-15 md:p-2 xmd:p-2 lg:p-4"></div>
            <img className="object-scale-down" src={twiter} alt="twiter" />
          </button>
          <button className="relative flex max-h-16 max-w-16 items-center justify-center rounded-3xl p-2 md:p-2 xmd:p-2 lg:p-2">
            <div className="absolute h-full w-full rounded-3xl bg-primary p-2 opacity-15 md:p-2 xmd:p-2 lg:p-4"></div>
            <img className="object-scale-down" src={youtube} alt="youtube" />
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
