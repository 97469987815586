import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import RoundButtonSecond from "./RoundButtonSecond";

interface AccordionProps {
  id: string;
  title: string;
  description: string;
}

const Accordion = ({ id, title, description }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`card-borders mb-7 rounded-3xl p-7 transition-all duration-500 ease-in-out lg:xmd:md:px-14 lg:xmd:md:py-10 ${isOpen ? "bg-primary text-white" : "bg-gray text-black"}`}
    >
      <motion.div className="flex flex-row items-center justify-between transition-all ease-in-out">
        <div className="flex w-4/5 flex-row items-center lg:xmd:md:w-full">
          <h1 className="font-poppins text-2xl lg:xmd:md:text-6xl">{id}</h1>
          <h1 className="ml-2 font-poppinsSemiBold text-base lg:xmd:md:ml-6 lg:xmd:md:text-2xl">
            {title}
          </h1>
        </div>
        <div className="relative flex items-center justify-end">
          <RoundButtonSecond
            isActive={isOpen}
            action={() => setIsOpen((prev) => !prev)}
          />
        </div>
      </motion.div>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            className="mt-5 transition-all ease-in-out"
            initial={{ opacity: 0, y: "-10%" }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              opacity: 0,
              y: "-10%",
              transition: {
                duration: 0.5,
              },
            }}
          >
            <span className="font-poppins text-sm lg:text-xl">
              {description}
            </span>
          </motion.div>
        ) : (
          ""
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
