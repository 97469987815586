import { useNavigate } from "react-router-dom";
import { tromp } from "../assets";
import Carousel from "../shared/subcomponents/Carousel";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";
import { styles } from "../theme/styles";

const Onboard = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col pt-8">
      <div
        className={`flex w-full flex-col lg:flex-row ${styles.paddingContainer}`}
      >
        <div className="mt-3 flex items-center justify-start lg:w-5/12">
          <div className="flex flex-col items-start justify-center">
            <h1 className="object-scale-down font-calSans text-4xl font-bold">
              Soluciones innovadoras para desafíos actuales y exigentes.
            </h1>
            <p className="mt-6 text-justify font-poppins">
              Proporcionamos soluciones innovadoras tanto tecnológicas como
              metodológicas para abordar los desafíos más actuales y exigentes
              mediante el desarrollo de software.
            </p>
            <div className="mt-6">
              <PrincipalButton
                handleAction={() => {
                  navigate("/contacto");
                }}
                width="w-full"
                label="Conversemos"
                classPadding="py-3 px-8"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center lg:w-8/12">
          <img
            className="aspect-square h-96 w-96 object-contain lg:h-5/6 lg:w-5/6"
            src={tromp}
            alt="tromp-illustration-pacificode"
          />
        </div>
      </div>

      <div className="border-1 flex h-16 flex-row overflow-hidden">
        <Carousel />
      </div>
    </div>
  );
};

export default Onboard;
