// import React from "react";
import { styles } from "../../theme/styles";
import Header from "../../components/Header";
import { DefaultLayoutProps } from "../../types";
import Footer from "../../components/Footer";
import ScrollToTop from "../subcomponents/ScrollToTop";

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return (
    <div className={`${styles.flexCol} justify-center`}>
      <ScrollToTop />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default DefaultLayout;
