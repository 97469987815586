import logo from "/logo.svg";
import "../App.css";

const Privacy = () => {
  return (
    <>
      <div className="flex flex-col items-center px-[20px] pb-9 md:px-[80px] lg:items-end lg:px-[100px]">
        <div className="my-10 flex w-full justify-center pb-9">
          <a href="#" target="_blank">
            <img src={logo} className="logo" alt="Pacificode logo" />
          </a>
        </div>
        <div className="flex w-full flex-col justify-around lg:flex-row">
          <div className="flex w-10/12 flex-col">
            <h1 className="CalSans mb-8 text-center text-3xl font-bold">
              Política de Privacidad
            </h1>
            <p className="poppins mb-5">
              Esta Política de Privacidad describe cómo Pacificode ("nosotros",
              "nuestro" o "nos") recopila, utiliza y comparte información cuando
              utilizas nuestra aplicación móvil (en adelante, la "Aplicación").
            </p>
            <h1 className="CalSans mb-8 text-start text-3xl font-bold">
              Información que recopilamos:
            </h1>
            <p className="poppins mb-5">
              No recopilamos información personal identificable de nuestros
              usuarios cuando utilizan la Aplicación. Sin embargo, es importante
              tener en cuenta que cuando eliges iniciar sesión en la Aplicación,
              es posible que se te solicite proporcionar cierta información de
              inicio de sesión, como tu dirección de correo electrónico y
              contraseña. Esta información se utiliza únicamente para autenticar
              tu identidad y permitir el acceso a las funciones de la
              Aplicación.
            </p>
            <h1 className="CalSans mb-8 text-3xl font-bold">
              Uso de la información:
            </h1>
            <p className="poppins mb-5">
              La información de inicio de sesión que proporcionas se utiliza
              exclusivamente para autenticar tu identidad y permitir el acceso
              seguro a la Aplicación.
            </p>
            <h1 className="CalSans mb-8 text-3xl font-bold">
              Divulgación de información:
            </h1>
            <p className="poppins mb-5">
              No compartimos, vendemos ni intercambiamos información de
              identificación personal de nuestros usuarios con terceros.
            </p>
            <h1 className="CalSans mb-8 text-3xl font-bold">Seguridad:</h1>
            <p className="poppins mb-5">
              Tomamos medidas razonables para proteger la información de
              nuestros usuarios contra accesos no autorizados, alteraciones,
              divulgaciones o destrucciones no autorizadas.
            </p>
            <h1 className="CalSans mb-8 text-3xl font-bold">
              Cambios en esta Política de Privacidad:
            </h1>
            <p className="poppins mb-5">
              Nos reservamos el derecho de actualizar o cambiar nuestra Política
              de Privacidad en cualquier momento y debes revisar esta Política
              periódicamente. Tu uso continuado de la Aplicación después de la
              publicación de cualquier modificación a la Política de Privacidad
              constituirá tu reconocimiento de los cambios y tu consentimiento
              para cumplir y estar sujeto a la Política de Privacidad
              modificada.
            </p>
            <h1 className="CalSans mb-8 text-3xl font-bold">Contacto:</h1>
            <p className="poppins mb-5">
              Si tienes alguna pregunta sobre esta Política de Privacidad, ponte
              en contacto con nosotros en{" "}
              <a
                href="mailTo:info@pacificodes.com"
                className="text-secondary underline"
              >
                info@pacificodes.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
