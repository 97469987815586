interface SubHeaderProps {
  title: string;
  description: string;
  type?: string;
}

const SubHeader = ({ title, description, type = "type1" }: SubHeaderProps) => {
  return (
    <>
      {type === "type1" ? (
        <div className="mb-14 flex flex-col items-center justify-start lg:flex-row">
          <h2 className="rounded-md bg-primary px-2 font-calSans text-4xl font-bold lg:mr-10">
            {title}
          </h2>
          <p className="mt-6 text-center font-poppins text-lg font-light md:text-justify xmd:text-justify lg:w-2/6 lg:text-justify">
            {description}
          </p>
        </div>
      ) : (
        <div className="justify-cente flex w-full flex-col items-center gap-5 py-8">
          <h2 className="rounded-md bg-primary px-2 font-calSans text-4xl font-bold lg:mr-10">
            {title}
          </h2>
          <div className="w-full px-5 text-center md:w-1/2">
            <span className="font-poppinsLight text-lg">{description}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default SubHeader;
