import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import PostPage from "./pages/PostPage";
import CasesPage from "./pages/CasesPage";
import Privacy from "./pages/Privacy";
import "./App.css";
import { AnimatePresence } from "framer-motion";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Router>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" Component={HomePage} />
            <Route path="*" Component={NotFoundPage} />
            <Route path="/servicios" Component={ServicesPage} />
            <Route path="/casos-de-exito" Component={CasesPage} />
            <Route path="/nosotros" Component={AboutPage} />
            <Route path="/contacto" Component={ContactPage} />
            <Route path="/blog" Component={BlogPage} />
            <Route path="/blog/:slug" Component={PostPage} />
            <Route path="/privacy" Component={Privacy} />
          </Routes>
        </AnimatePresence>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
