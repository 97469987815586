
    import { createChat } from 'https://cdn.jsdelivr.net/npm/@n8n/chat/dist/chat.bundle.es.js';

    createChat({
      webhookUrl: 'https://primary-production-23ee.up.railway.app/webhook/9b7ddd4f-f9a0-433f-aa0d-f15294ca166a/chat',
      showWelcomeScreen: false,
      defaultLanguage: 'es',
      initialMessages: [
        '¡Hola! 👋 Bienvenido a Pacificode.',
        'Estamos aquí para ti, dime en qué podemos ayudarte hoy.'
      ],
      i18n: {
        en: {
          title: 'Hi there! 👋',
          subtitle: "Start a chat. We're here to help you 24/7.",
          footer: '',
          getStarted: 'New Conversation',
          inputPlaceholder: 'Type your question..',
        },
        es: {
          title: '¡Hola! 👋',
          subtitle: "Inicia una conversación. Estamos aquí para ayudarte 24/7.",
          footer: '',
          getStarted: 'Nueva Conversación',
          inputPlaceholder: 'Escribe tu pregunta..',
        },
      }
    });
  