const styles = {
  flexCol: "flex flex-col",
  flexRow: "flex flex-row",
  paddingContainer: "lg:px-[7vw] md:px-[7vw] px-5",
  main: "flex h-[80vh] flex-col lg:md:sm:h-[85vh] lg:flex-row lg:justify-between",
  inNav: "w-full flex justify-between items-center",
  menu: "list-none lg:flex-row lg:text-[20px] text-[16px] px-[10px]",
};

export { styles };
