import DefaultLayout from "../shared/layouts/DefaultLayout";
import SubHeader from "../shared/subcomponents/SubHeader";
import DATA from "../utils/data";
import "../App.css";
import SectionWrapper from "../hoc/SectionWrapper";

function BlogPage() {
  return (
    <DefaultLayout>
      <SubHeader
        title={DATA.blog.title}
        description={DATA.blog.description}
        type="type2"
      />
    </DefaultLayout>
  );
}

const BlogPageWithTransition = SectionWrapper(BlogPage);

export default BlogPageWithTransition;
