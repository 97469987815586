import SubHeader from "../shared/subcomponents/SubHeader";
import DATA from "../utils/data";
import BigCard from "../shared/subcomponents/BigCard";
import { tokioCo } from "../assets";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";
import { useForm } from "react-hook-form";
import { SendNotificationContact } from "../utils/actions.notifications";
import { toast } from "react-hot-toast";
import { useState } from "react";

interface IContact {
  showHeader?: boolean;
}

interface IContactData {
  name: string;
  email: string;
  phone: string;
  message: string;
  date: string;
}

const Contact = ({ showHeader = true }: IContact) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IContactData>();

  const onSubmit = async (data: IContactData) => {
    try {
      setLoading(true);
      const now = new Date();
      data.date = now.toISOString();
      const res = await SendNotificationContact(data);
      console.log(res);

      // if (res.status === 200) {
      toast.success("Excelente! Gracias por suscribirte");
      // }
      setLoading(false);
      reset();
    } catch (error) {
      toast.error("Error al suscribirte");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div
      className={`flex w-full flex-col pt-14 transition-all duration-500 ease-in-out lg:pt-32`}
    >
      <div className="px-5 md:px-[7vw] lg:px-[7vw]">
        {showHeader && (
          <SubHeader
            description={DATA.contact.description}
            title={DATA.contact.title}
          />
        )}
        <div className="flex w-full flex-col">
          <BigCard height="h-4/5 px-0 py-4">
            <div className="flex w-full flex-row justify-between">
              <div className="flex w-full flex-col px-5 py-2 sm:px-10 md:py-5 xmd:py-5 xmd:pl-20 lg:py-5 lg:pl-20">
                <form className="flex h-full w-full flex-col gap-3">
                  <div className="flex w-full flex-col">
                    <label htmlFor="name" className="text-gray-500">
                      Nombre*
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      className={`bg-gray-100 my-2 w-full rounded-2xl border border-white p-4 focus:border-primary focus:outline-none ${errors.name && "border-red-500"}`}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <label htmlFor="email" className="text-gray-500">
                      Correo Electrónico*
                    </label>
                    <input
                      type="text"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      className={`bg-gray-100 my-2 w-full rounded-2xl border border-white p-4 focus:border-primary focus:outline-none ${errors.email && "border-red-500"}`}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <label htmlFor="phone" className="text-gray-500">
                      Teléfono*
                    </label>
                    <input
                      type="text"
                      {...register("phone", {
                        required: true,
                        pattern: /^[0-9]+$/i,
                      })}
                      className={`bg-gray-100 my-2 w-full rounded-2xl border border-white p-4 focus:border-primary focus:outline-none ${errors.phone && "border-red-500"}`}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <label htmlFor="message" className="text-gray-500">
                      Mensaje*
                    </label>
                    <input
                      type="text"
                      {...register("message", {
                        required: true,
                        maxLength: 100,
                        minLength: 10,
                      })}
                      placeholder="Escribe tu mensaje..."
                      className={`border-gray-100 my-4 w-full border-b bg-transparent p-2 focus:border-primary focus:outline-none ${errors.message && "border-red-500"}`}
                    />
                  </div>
                  <div className="hidden md:flex xmd:flex lg:flex">
                    <PrincipalButton
                      type="submit"
                      classPadding="py-3 p-6"
                      label={loading ? "Enviando..." : "Enviar mensaje"}
                      width="w-auto"
                      disabled={loading}
                    />
                  </div>
                </form>
              </div>
              <div className="hidden w-full flex-row justify-end py-5 xmd:flex">
                <img
                  src={tokioCo}
                  className="max-h-[75vh] object-scale-down"
                  alt="contacto"
                />
              </div>
            </div>
          </BigCard>
          {!loading ? (
            <PrincipalButton
              classPadding="py-5 p-8 mt-5 lg:hidden xmd:hidden md:hidden flex"
              label="Enviar mensaje"
              width="w-full text-center"
              handleAction={handleSubmit(onSubmit)}
            />
          ) : (
            <PrincipalButton
              classPadding="py-5 p-8 mt-5 lg:hidden xmd:hidden md:hidden flex"
              label="Enviando..."
              width="w-full text-center"
              handleAction={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
