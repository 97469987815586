import axios from "axios";

const BASEURL = "https://hook.us2.make.com/1jtc6d3rvzqqslka7qux9uupob76j1id";

export const SendNotificationContact = async (data: unknown) => {
  const res = await axios.post(BASEURL, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  console.log(res);

  return res;
};
