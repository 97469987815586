import { Segment, stagger, useAnimate } from "framer-motion";
import { useEffect, useState } from "react";

function useMenuAnimation() {
  const [toggle, setToggle] = useState(false);
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations: Segment[] = toggle
      ? [
          [
            "#menu",
            { transform: "translateX(0%)" },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.2 },
          ],
          [
            "li",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
            { delay: stagger(0.05), at: "-0.1" },
          ],
        ]
      : [
          [
            "li",
            { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
            { delay: stagger(0.05, { from: "last" }), at: "<" },
          ],
          ["#menu", { transform: "translateX(-100%)" }, { at: "-0.1" }],
        ];

    animate([
      [
        "path.top",
        { d: toggle ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
        { at: "<" },
      ],
      ["path.middle", { opacity: toggle ? 0 : 1 }, { at: "<" }],
      [
        "path.bottom",
        { d: toggle ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
        { at: "<" },
      ],
      ...menuAnimations,
    ]);
  }, [toggle, animate]);

  return { scope, setToggle, toggle };
}

export default useMenuAnimation;
