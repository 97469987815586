import Cases from "../components/Cases";
import Contact from "../components/Contact";
import Onboard from "../components/Onboard";
import Process from "../components/Process";
import Services from "../components/Services";

import Testimonials from "../components/Testimonials";
import SectionWrapper from "../hoc/SectionWrapper";
import DefaultLayout from "../shared/layouts/DefaultLayout";

function HomePage() {
  return (
    <DefaultLayout>
      <Onboard />
      <Services />
      <Cases />
      <Process />
      <Testimonials />
      <Contact />
    </DefaultLayout>
  );
}

const HomePageWithTransition = SectionWrapper(HomePage);

export default HomePageWithTransition;
