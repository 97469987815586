import { styles } from "../theme/styles";
import SubHeader from "../shared/subcomponents/SubHeader";
import Accordion from "../shared/subcomponents/Accordeon";
import DATA from "../utils/data";

const Process = () => {
  return (
    <div
      className={`flex w-full flex-col  pt-14 lg:pt-32 ${styles.paddingContainer}`}
    >
      <SubHeader
        description={DATA.process.description}
        title={DATA.process.title}
      />
      <div className="flex w-full flex-col ">
        {DATA.process.workProcess.map((item) => (
          <Accordion key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Process;
