import { NavLink, useNavigate } from "react-router-dom";
import { close, logo, logoWhite, menu } from "../assets/index";
import useMenuAnimation from "../hooks/useMenuAnimation";
import { styles } from "../theme/styles";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";

const Header = () => {
  const { scope, setToggle, toggle } = useMenuAnimation();
  const navigate = useNavigate();

  return (
    <nav
      className={`mt-9 flex h-[50px] w-full md:mt-14 md:h-[68px] lg:mt-14 lg:h-[68px] ${styles.paddingContainer}`}
    >
      <div className={styles.inNav}>
        <div className="hidden md:flex xmd:flex lg:flex">
          <NavLink to={"/"}>
            <img
              className="md:w-44 xmd:w-52 lg:w-60"
              src={logo}
              alt="logo-pacificode"
            />
          </NavLink>
        </div>
        <ul
          className={`${styles.menu} hidden items-center gap-3 object-scale-down md:flex xmd:flex lg:flex lg:gap-5`}
        >
          <li>
            <NavLink
              to={"/"}
              className="font-calSans sm:text-sm md:text-base lg:text-lg xl:text-xl"
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/nosotros"}
              className="font-calSans sm:text-sm md:text-base lg:text-lg xl:text-xl"
            >
              Nosotros
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/servicios"}
              className="font-calSans sm:text-sm md:text-base lg:text-lg xl:text-xl"
            >
              Servicios
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/casos-de-exito"}
              className="font-calSans sm:text-sm md:text-base lg:text-lg xl:text-xl"
            >
              Casos de éxito
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/blog"}
              className="font-calSans sm:text-sm md:text-base lg:text-lg xl:text-xl"
            >
              Blog
            </NavLink>
          </li>
          <li>
            <PrincipalButton
              handleAction={() => {
                navigate("/contacto");
              }}
              width="w-full"
              label="Contáctanos"
              classPadding="py-3 px-8"
            ></PrincipalButton>
          </li>
        </ul>
        <div
          ref={scope}
          className="flex h-[50px] w-screen items-center justify-between md:hidden xmd:hidden lg:hidden"
        >
          <NavLink to={"/"}>
            <img
              onClick={() => setToggle(!toggle)}
              className="h-[34px] w-40 cursor-pointer object-contain"
              src={logo}
              alt="menu-pacificode"
            />
          </NavLink>
          <div
            id="menu"
            className={`fixed left-0 top-0 z-20 flex
                h-full w-screen flex-col bg-secondary ${!toggle && "hidden translate-x-0"}`}
          >
            <div className="flex w-full bg-secondary py-10">
              <div className="flex w-screen flex-1 items-center justify-between pl-5 pr-9">
                <NavLink to={"/"}>
                  <img
                    onClick={() => setToggle(!toggle)}
                    className="h-[34px] w-40 cursor-pointer object-contain"
                    src={logoWhite}
                    alt="logo-pacificode"
                  />
                </NavLink>
                <img
                  src={close}
                  alt="close"
                  className="h-[16px] w-[24px] cursor-pointer object-contain"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                />
              </div>
            </div>
            <ul
              className="-gap-[1rem] mt-10 flex 
                list-none flex-col items-start justify-end"
            >
              <li
                id={"nosotros"}
                className={`cursor-pointer font-calSans text-[50px] 
                      font-bold uppercase tracking-[1px] text-white underline`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink to={"/nosotros"}>Nosotros</NavLink>
              </li>
              <li
                id={"servicios"}
                className={`cursor-pointer font-calSans text-[50px] 
                      font-bold uppercase tracking-[1px] text-white underline`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink to={"/servicios"}>Servicios</NavLink>
              </li>
              <li
                id={"casos"}
                className={`cursor-pointer font-calSans text-[50px] 
                      font-bold uppercase tracking-[1px] text-white underline`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink to={"/casos-de-exito"}>Casos de éxito</NavLink>
              </li>
              <li
                id={"blog"}
                className={`cursor-pointer font-calSans text-[50px] 
                      font-bold uppercase tracking-[1px] text-white underline`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink to={"/blog"}>Blog</NavLink>
              </li>
              <li
                id={"contacto"}
                className={`cursor-pointer font-calSans text-[50px] 
                      font-bold uppercase tracking-[1px] text-white underline`}
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <NavLink to={"/contacto"}>Contacto</NavLink>
              </li>
            </ul>
          </div>
          <img
            src={menu}
            alt="menu"
            className="h-[16px] w-[24px] cursor-pointer object-contain"
            onClick={() => setToggle(!toggle)}
          />
        </div>
      </div>
    </nav>
  );
};

export default Header;
