import { refrimax, refrimaxMini, vitam, vitamMini } from "../assets";
import DefaultLayout from "../shared/layouts/DefaultLayout";
import SubHeader from "../shared/subcomponents/SubHeader";
import { styles } from "../theme/styles";
import DATA from "../utils/data";
import "../App.css";
import { useEffect } from "react";
// import { API_KEY, APP_API_URL } from "../constants";
// import axios from "axios";
import SectionWrapper from "../hoc/SectionWrapper";

function CasesPage() {
  useEffect(() => {
    // axios.get(`${APP_API_URL}/cases`, {
    //   headers: {
    //     Authorization: `Bearer ${API_KEY}`,
    //   },
    // });
  }, []);
  return (
    <DefaultLayout>
      <SubHeader
        title={DATA.cases.title}
        description={DATA.cases.description}
        type="type2"
      />
      <div className={`${styles.paddingContainer} flex w-full flex-row gap-10`}>
        <div className={`grid w-full grid-flow-row-dense gap-10`}>
          <>
            <div className="col-span-2 h-[300px] w-full rounded-[45px] duration-500 ease-in-out hover:scale-105 hover:shadow-lg hover:transition">
              <div className="relative flex h-[300px] flex-row items-start object-scale-down">
                <div className="absolute flex h-[300px] w-full">
                  <img
                    src={refrimax}
                    className="hidden h-full w-full rounded-[45px] object-fill md:block"
                    alt="RefriDash - Caso 1"
                  />
                  <img
                    src={refrimaxMini}
                    className="flex h-full w-full rounded-[45px] object-fill md:hidden"
                    alt="RefriDash - Caso 1"
                  />
                </div>

                <div className="z-10 flex h-[300px] w-full flex-col justify-between p-5 md:pl-16 md:pr-5 ">
                  <div className="flex h-[260px] w-full flex-col justify-evenly px-2 md:w-1/2 ">
                    <h2 className="font-poppinsSemiBold text-2xl ">
                      RefriDash - Caso 1
                    </h2>
                    <span className="w-full font-poppinsMedium text-sm md:text-lg">
                      Para una empresa de mantenimiento industrial de equipos de
                      refrigeración, desarrollamos un software personalizado que
                      agilizó la gestión mensual de mantenimientos. Esta
                      solución optimizó la programación de tareas, mejoró la
                      asignación de recursos y facilitó el seguimiento del
                      progreso de los trabajo...
                    </span>
                  </div>
                  <div className="mt-5 flex flex-row justify-between">
                    <span className="font-poppinsLight text-sm">
                      Noviembre, 2023
                    </span>
                    <span className="font-poppinsLight text-sm md:text-white"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 h-[300px] w-full rounded-[45px] duration-500 ease-in-out hover:scale-105 hover:shadow-lg hover:transition">
              <div className="relative flex h-[300px] flex-row items-start object-scale-down">
                <div className="absolute flex h-[300px] w-full">
                  <img
                    src={vitam}
                    className="hidden h-full w-full rounded-[45px] object-fill md:block"
                    alt="VitamCredit"
                  />
                  <img
                    src={vitamMini}
                    className="flex h-full w-full rounded-[45px] object-fill md:hidden"
                    alt="VitamCredit"
                  />
                </div>

                <div className="z-10 flex h-[300px] w-full flex-col justify-between p-5 md:pl-16 md:pr-5 ">
                  <div className="flex h-[260px] w-full flex-col justify-evenly px-2 md:w-1/2 ">
                    <h2 className="font-poppinsSemiBold text-2xl ">
                      VitamCredit - Caso 2
                    </h2>
                    <span className="w-full font-poppinsMedium text-sm md:text-lg">
                      Para una empresa de microcréditos para la compra de motos,
                      desarrollamos un sistema de gestión personalizado que
                      optimizó los procesos internos y mejoró la productividad
                      en un 50%...
                    </span>
                  </div>
                  <div className="mt-5 flex flex-row justify-between">
                    <span className="font-poppinsLight text-xs">
                      Marzo, 2021
                    </span>
                    <span className="font-poppinsLight text-xs"></span>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DefaultLayout>
  );
}

const CasesPageWithTransition = SectionWrapper(CasesPage);

export default CasesPageWithTransition;
