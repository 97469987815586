import Contact from "../components/Contact";
import SectionWrapper from "../hoc/SectionWrapper";
import DefaultLayout from "../shared/layouts/DefaultLayout";
import SubHeader from "../shared/subcomponents/SubHeader";
import { styles } from "../theme/styles";

const AboutPage = () => {
  return (
    <DefaultLayout>
      <SubHeader
        title="Nosotros"
        description="¡Estamos Cambiando las Reglas del Juego!"
        type="type2"
      />
      <div
        className={`${styles.paddingContainer} grid grid-rows-3 gap-10 sm:grid-cols-2 md:grid-cols-2 xmd:grid-cols-2 lg:grid-cols-2`}
      ></div>
      <Contact />
    </DefaultLayout>
  );
};

const AboutPageWithTransition = SectionWrapper(AboutPage);

export default AboutPageWithTransition;
