import "../../App.css";

interface BigCardProps {
  backgroundColor?: string;
  children?: React.ReactNode;
  height?: string;
}

const BigCard = ({
  backgroundColor = "bg-[#F3F3F3]",
  children,
  height = "h-[347px]",
}: BigCardProps) => {
  return (
    <div
      className={`flex ${height} w-full flex-col items-center justify-between rounded-[45px] lg:flex-row  ${backgroundColor}`}
    >
      {children}
    </div>
  );
};

export default BigCard;
