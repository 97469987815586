import { motion } from "framer-motion";

const SectionWrapper = (Component: React.FC) => {
  return () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "backInOut",
        duration: 0.5,
      }}
    >
      <Component />
    </motion.div>
  );
};

export default SectionWrapper;
