import { NavLink } from "react-router-dom";
import { face, instagram, linked, logoWhite, twiter } from "../assets";
import DATA from "../utils/data";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SendNotificationContact } from "../utils/actions.notifications";
import toast from "react-hot-toast";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: unknown) => {
    try {
      setLoading(true);
      const res = await SendNotificationContact(data);
      if (res.status === 200) {
        toast.success("Excelente! Gracias por suscribirte");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error al suscribirte");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className={`flex w-full flex-col pt-14`}>
      <div className="flex flex-col gap-8 bg-[#191A23] px-10 pb-3 pt-12 transition-all duration-500 ease-in-out sm:bg-secondary md:gap-10 md:bg-secondary xmd:gap-14 xmd:bg-secondary lg:gap-14 lg:bg-secondary lg:px-16">
        <nav className="flex w-full">
          <div className="flex w-full flex-col  items-center justify-between gap-5 sm:items-center xmd:flex-row xmd:items-end lg:flex-row lg:items-end">
            <div className="flex">
              <a href={`#`}>
                <img className="w-40" src={logoWhite} alt="logo" />
              </a>
            </div>
            <ul className="flex flex-col gap-3 text-center sm:flex-row md:flex-row xmd:flex-row lg:flex-row lg:gap-5">
              <li onClick={() => {}}>
                <NavLink
                  to={"/"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/nosotros"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Nosotros
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/servicios"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Servicios
                </NavLink>
              </li>
              <li onClick={() => {}}>
                <NavLink
                  to={"/casos"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Casos de exito
                </NavLink>
              </li>
              <li onClick={() => {}}>
                <NavLink
                  to={"/blog"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contacto"}
                  className="object-scale-down font-poppins text-lg text-white underline md:text-lg xmd:text-base lg:text-base xl:text-lg"
                >
                  Contáctanos
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className="flex flex-col items-center gap-5 xmd:flex-row xmd:items-start xmd:justify-between xmd:gap-1 lg:flex-row lg:items-start lg:justify-between lg:gap-1">
          <div className="flex flex-col items-center xmd:mb-auto xmd:items-start lg:mb-auto lg:items-start">
            <div className="flex flex-row">
              <h1 className="rounded-md bg-primary px-2 font-calSans text-xl font-bold lg:mr-10">
                Contáctanos
              </h1>
            </div>
            <span className="mt-5 font-poppinsLight text-sm text-white">
              Correo:
              <a
                href={`mailto:${DATA.contact.email}`}
                className="ml-1 font-poppinsLight text-sm text-white underline"
              >
                {DATA.contact.email}
              </a>
            </span>
            <span className="mt-5 font-poppinsLight text-sm text-white">
              Telefono-1: {DATA.contact.phone}
            </span>
            <span className="mt-2 font-poppinsLight text-sm text-white">
              Telefono-2: {DATA.contact.phone2}
            </span>
            <span className="mt-5 font-poppinsLight text-sm text-white">
              {DATA.contact.ubication}
            </span>
          </div>
          <div className="flex w-full sm:w-3/4 md:w-3/4 xmd:w-2/3 lg:w-2/3 lg:flex-col">
            <div className="flex h-full w-full flex-col items-start justify-center gap-2 rounded-2xl bg-[#292A32] p-10">
              <h1 className="font-poppinsSemiBold text-xl font-bold text-white">
                Suscribete a nuestro boletín
              </h1>
              <div className="flex w-full flex-col items-center justify-center gap-5 md:flex-row xmd:flex-row lg:flex-row">
                <input
                  className={`w-full rounded-2xl border border-white bg-[#292A32] px-3 py-5 font-poppinsLight text-sm text-white hover:border-primary active:border-primary ${errors.email ? "border-red-500" : "border-white"}`}
                  type="text"
                  {...register("email", { required: true })}
                  placeholder="Ingresa tu correo"
                />
                {!loading ? (
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className="w-full rounded-2xl bg-primary px-2 py-5 font-poppinsLight text-sm text-white hover:bg-secondary sm:px-6 md:w-1/3 md:px-6 xmd:w-1/3 xmd:px-6 lg:w-1/3 lg:px-9"
                  >
                    Suscribirse
                  </button>
                ) : (
                  <button className="w-full rounded-2xl bg-gray px-2 py-5 font-poppinsLight text-sm text-black sm:px-6 md:w-1/3 md:px-6 xmd:w-1/3 xmd:px-6 lg:w-1/3 lg:px-9">
                    Enviando...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-3 border-t border-white pb-5 pt-12 font-poppinsLight text-white md:flex-col xmd:flex-row xmd:items-start xmd:justify-between lg:flex-row lg:items-start lg:justify-between">
          <p>© 2024 Pacificode. Todos los derechos reservados.</p>
          {/* <NavLink className="underline" to="/privacy">
            Política de privacidad
          </NavLink>
          <NavLink className="underline" to="/terms">
            Términos y condiciones
          </NavLink> */}
          {/* social  */}
          <div className="flex flex-row gap-5">
            <a href={DATA.social.facebook} target="_blank" rel="noreferrer">
              <img src={face} alt="facebook-logo" />
            </a>
            <a href={DATA.social.instagram} target="_blank" rel="noreferrer">
              <img src={instagram} alt="instagram-logo" />
            </a>
            <a href={DATA.social.linkedin} target="_blank" rel="noreferrer">
              <img src={linked} alt="linkedin-logo" />
            </a>
            <a href={DATA.social.twitter} target="_blank" rel="noreferrer">
              <img src={twiter} alt="twitter-logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
