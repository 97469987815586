import { minus, plus } from "../../assets";

interface RoundButtonSecondProps {
  action?: () => void;
  isActive: boolean;
}

const RoundButtonSecond = ({ isActive, action }: RoundButtonSecondProps) => {
  return (
    <button
      onClick={action}
      className={`absolute flex h-8 w-8 items-center justify-center rounded-full transition-all duration-500 ease-in-out lg:xmd:md:h-10 lg:xmd:md:w-10 ${isActive ? "bg-secondary" : "border border-secondary bg-white"}`}
    >
      <img
        src={isActive ? minus : plus}
        className="h-4 w-4 text-black lg:xmd:md:h-6 lg:xmd:md:w-6"
        alt="toggle"
      />
    </button>
  );
};

export default RoundButtonSecond;
