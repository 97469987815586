import { arrowBlack, arrowPrimary, arrowWhite } from "../../assets";

interface RoundButtonProps {
  backgroundColor?: string;
  action: () => void;
}

const RoundButton = ({ backgroundColor, action }: RoundButtonProps) => {
  const arrow = Math.random() > 0.5 ? arrowWhite : arrowPrimary;

  return (
    <div
      onClick={action}
      className={`flex h-10 w-10 items-center justify-center rounded-full ${backgroundColor} cursor-pointer`}
    >
      <img
        src={backgroundColor === "bg-white" ? arrowBlack : arrow}
        className="text-black"
        alt="arrow"
      />
    </div>
  );
};

export default RoundButton;
