const DATA = {
  process: {
    title: "Como Trabajamos",
    description: "Guía paso a paso de como alcanzaremos tus objetivos.",
    workProcess: [
      {
        id: "01",
        title: "Consulta",
        description:
          "Durante la consulta inicial, discutiremos tus metas y objetivos comerciales, tu público objetivo y la situación actual de tus procesos. Esto nos permitirá comprender sus necesidades y adaptar nuestros servicios para que se ajusten mejor a sus necesidades.",
      },
      {
        id: "02",
        title: "Investigación y levantamineto de requisitos",
        description:
          "Durante la fase de Investigación y Levantamiento de Requisitos, trabajaremos estrechamente contigo para comprender a fondo tus necesidades comerciales y los requisitos específicos del proyecto. Realizaremos entrevistas, análisis de documentos y reuniones para recopilar información detallada sobre tus objetivos, expectativas y limitaciones. Este proceso nos permitirá establecer una base sólida para el desarrollo del software, asegurando que el producto final cumpla con tus necesidades y expectativas.",
      },
      {
        id: "03",
        title: "Diseño y desarrollo",
        description:
          "Durante la etapa de Diseño y Desarrollo, nuestro equipo de expertos traducirá los requisitos recopilados en la fase anterior en soluciones tangibles y funcionales. Empezaremos por crear una arquitectura sólida y un diseño detallado, asegurándonos de considerar la usabilidad, la escalabilidad y la seguridad del software. Luego, pasaremos al desarrollo propiamente dicho, utilizando las mejores prácticas y tecnologías adecuadas para crear un producto de alta calidad que cumpla con tus expectativas y requisitos específicos. Este proceso se llevará a cabo de manera colaborativa, con revisiones periódicas para garantizar que el desarrollo esté en línea con tus necesidades y expectativas.",
      },
      {
        id: "04",
        title: "Pruebas e implementación",
        description:
          "Durante la fase de Pruebas e Implementación, llevaremos a cabo exhaustivas pruebas para garantizar que el software cumpla con los estándares de calidad y funcione según lo previsto. Utilizaremos una variedad de técnicas de prueba, incluyendo pruebas de unidad, integración, sistema y aceptación, para identificar y corregir cualquier error o defecto. Una vez que el software haya pasado exitosamente todas las pruebas, procederemos con la implementación, asegurándonos de que se despliegue de manera efectiva en tu entorno de producción. Durante este proceso, proporcionaremos soporte continuo y capacitación si es necesario, para garantizar una transición sin problemas y una adopción exitosa del software por parte de tus usuarios finales.",
      },
      {
        id: "05",
        title: "Monitoreo y Optimización",
        description:
          "Durante la fase de Monitoreo y Optimización, implementaremos herramientas y procedimientos para supervisar de cerca el rendimiento y la estabilidad del software en tiempo real. Esto incluye monitorear métricas clave como el tiempo de respuesta, la utilización de recursos y la disponibilidad del sistema. Además, analizaremos los datos recopilados para identificar áreas de mejora y oportunidades de optimización. Utilizaremos esta información para realizar ajustes proactivos y garantizar que el software funcione de manera óptima en todo momento. Además, estaremos disponibles para brindar soporte continuo y realizar actualizaciones según sea necesario para mantener el software actualizado y seguro.",
      },
      {
        id: "06",
        title: "Informes y comunicación",
        description:
          "Durante la fase de Informes y Comunicación, mantendremos una comunicación transparente y constante contigo, proporcionándote informes regulares sobre el progreso del proyecto, los hitos alcanzados y cualquier problema o desafío que surja. Estos informes estarán diseñados para brindarte una visión clara y detallada del estado del proyecto, permitiéndote tomar decisiones informadas y realizar ajustes según sea necesario. Además, estaremos disponibles para responder cualquier pregunta o inquietud que puedas tener, y trabajaremos estrechamente contigo para asegurarnos de que estés completamente informado y satisfecho con el progreso del proyecto en todo momento.",
      },
      {
        id: "07",
        title: "Mejora continua",
        description:
          "Durante la fase de Mejora Continua, nos comprometemos a revisar constantemente el proceso de desarrollo de software y a buscar formas de optimizarlo y perfeccionarlo. Esto implica recopilar comentarios de los usuarios finales, analizar métricas de rendimiento y realizar evaluaciones periódicas del proceso en sí mismo. Utilizaremos esta retroalimentación para identificar áreas de mejora y oportunidades de crecimiento, implementando cambios y ajustes según sea necesario. Nuestro objetivo es garantizar que nuestro proceso de desarrollo de software sea ágil, eficiente y esté en constante evolución para satisfacer tus necesidades cambiantes y garantizar resultados excepcionales a largo plazo.",
      },
    ],
  },
  social: {
    facebook: "https://www.facebook.com/pacificode",
    instagram: "https://www.instagram.com/pacificode.co",
    linkedin: "https://www.linkedin.com/company/pacificode",
    twitter: "https://www.twitter.com/pacificode_co",
  },
  cases: {
    title: "Casos de exito",
    description:
      "Explore ejemplos de la vida real de nuestro éxito comprobado en desarrollo de software a través de nuestros exito",
    cases: [
      {
        id: 1,
        title: "RefriDash - Caso 1",
        description:
          "Para Refrimax, empresa de mantenimiento industrial de equipos de refrigeración, desarrollamos un software personalizado que agilizó la gestión mensual de mantenimientos. Esta solución optimizó la programación de tareas, mejoró la asignación de recursos y facilitó el seguimiento del progreso de los trabajos. Como resultado, la empresa experimentó una reducción del 25% en los tiempos de planificación y una mejora del 30% en la eficiencia operativa. Además, implementamos funciones de generación de informes automatizados para proporcionar una visión clara del estado de los equipos y las actividades de mantenimiento.",
        image: "refrimax",
        customer: "Refrimax Services S.A.S",
        date: "Noviembre, 2023",
      },
      {
        id: 2,
        title: "Piki 7 - Caso 2",
        description:
          "Para Piki 7, empresa de logística de transporte, creamos un sistema de gestión integral que automatizó la programación de turnos para terminales de contenedores y simplificó el proceso de reserva con múltiples navieras. Esto resultó en una mejora del 40% en la eficiencia operativa y una reducción del 30% en los tiempos de respuesta. Además, implementamos un tablero de control en tiempo real para monitorear el estado de las operaciones y optimizar la toma de decisiones.",
        image: "blog3",
        customer: "Parque Industrial Kilometro 7 S.A.S",
        date: "Septiembre, 2022",
      },
      {
        id: 3,
        title: "VitamCredit - Caso 3",
        description:
          "Para Vitam venture, empresa de microcréditos para la compra de motos, desarrollamos un sistema de gestión personalizado que optimizó los procesos internos y mejoró la productividad en un 50%.",
        image: "blog2",
        customer: "Vitam Venture",
        date: "Marzo, 2021",
      },
    ],
  },
  services: {
    title: "Servicios",
    description:
      "Nuestra agencia digital ofrece servicios para impulsar el crecimiento y éxito en línea de las empresas. Estos servicios incluyen:",
    services: [
      {
        id: 0,
        title: "Desarrollo de Software a Medida",
        description:
          "Desarrollamos software a medida para satisfacer tus necesidades comerciales específicas y ayudarte a alcanzar tus objetivos.",
        image: "tokio",
        backgroundColor: "bg-gray",
      },
      {
        id: 1,
        title: "Impulsate con IA y Machine Learning",
        description:
          "Implementamos soluciones de inteligencia artificial y aprendizaje automático para mejorar la eficiencia y la productividad de tu empresa.",
        image: "tokioC",
        backgroundColor: "bg-primary",
      },
      {
        id: 2,
        title: "Consultoría En Estrategia Digital",
        description:
          "Proporcionamos consultoría en estrategia digital para ayudarte a identificar oportunidades de crecimiento y optimizar tu presencia en línea.",
        image: "tokioL",
        backgroundColor: "bg-secondary",
      },
      {
        id: 3,
        title: "Desarrollo de marca e email Marketing",
        description:
          "Creamos estrategias de marca y marketing por correo electrónico efectivas para aumentar el conocimiento de la marca y la participación del público.",
        image: "tokioE",
        backgroundColor: "bg-gray",
      },
      {
        id: 4,
        title: "Desarrollo de Aplicaciones Móviles",
        description:
          "Diseñamos y desarrollamos aplicaciones móviles personalizadas para iOS y Android que brindan una experiencia de usuario excepcional.",
        image: "tokioB",
        backgroundColor: "bg-primary",
      },
      {
        id: 5,
        title: "Automatización de Procesos de Negocio",
        description:
          "Implementamos soluciones de automatización de procesos de negocio para mejorar la eficiencia operativa y reducir los costos.",
        image: "tokioV",
        backgroundColor: "bg-secondary",
      },
    ],
  },
  team: {
    title: "Nuestro equipo",
    description:
      "Conoce al equipo de expertos detrás de nuestro éxito y encargado del desarrollo tu software.",
    team: [
      {
        id: "01",
        title: "Andy Caicedo Rivas",
        position: "CEO & Co-Fundador",
        image:
          "https://media.licdn.com/dms/image/D4E03AQE-eN0gxMAXyw/profile-displayphoto-shrink_800_800/0/1706819656328?e=1720051200&v=beta&t=7DMrAbJlLI-I177GHLutyiI9gx9AumNOm98XeC-rAqE",
        linkedin: "https://www.linkedin.com/in/andy-caicedo/",
        description:
          "Ingeniero de sistemas con mas de 10 años de experiencia en el desarrollo de software",
      },
      {
        id: "02",
        title: "Kevin Jair Richard",
        position: "CTO & Co-Fundador",
        image:
          "https://media.licdn.com/dms/image/D5635AQHzkKE9I-_f8g/profile-framedphoto-shrink_100_100/0/1632461649543?e=1715187600&v=beta&t=bLMhbk811gsNDnUw3SeuIOIR9icKMK9aPEJOqLcaGrI",
        linkedin: "https://www.linkedin.com/in/kevin-jair-389a681a3/",
        description:
          "Ingeniero de sistemas con mas de 10 años de experiencia en el desarrollo de software",
      },
    ],
  },
  testimonials: {
    title: "Testimonios",
    description:
      "Escuche a nuestros clientes satisfechos: lea nuestros testimonios para obtener más información sobre nuestros servicios",
    testimonials: [
      {
        id: 1,
        client: "Saul Monard",
        position: "Café Pacifico",
        description:
          "Hemos estado trabajando con Pacificode durante el año pasado y hemos visto un aumento significativo en el tráfico del sitio web y en los clientes potenciales como resultado de sus esfuerzos. El equipo es profesional, receptivo y realmente se preocupa por el éxito de nuestro negocio. Recomendamos ampliamente Pacificode para cualquier empresa que busque aumentar su presencia en línea.",
      },
      {
        id: 2,
        client: "Johana Micolta",
        position: "Vitam Venture",
        description:
          "Gracias a la solución de gestión desarrollada por Pacificode, ahora podemos atender las solicitudes de microcréditos de manera más eficiente y rápida. El nuevo sistema ha simplificado nuestros procesos internos y nos ha permitido brindar un mejor servicio a nuestros clientes. Estamos muy satisfechos con los resultados y la colaboración con el equipo de Pacificode.",
      },
      {
        id: 3,
        client: "Jorge Gutierrez",
        position: "Refrimax Services S.A.S",
        description:
          "El software de gestión mensual de mantenimientos desarrollado por Pacificode ha sido una verdadera revolución para nuestro negocio. Nos ha permitido planificar y realizar los mantenimientos de manera más organizada y eficiente. Ahora podemos seguir fácilmente el progreso de cada tarea y generar informes detallados con solo unos clics. Estamos muy agradecidos por la colaboración y el excelente trabajo realizado por el equipo de Pacificode.",
      },
      {
        id: 4,
        client: "Roy Martelo",
        position: "Parque Industrial Kilometro 7 S.A.S",
        description:
          "El sistema de gestión integral desarrollado por Pacificode ha transformado por completo nuestra operativa logística. Ahora podemos coordinar de manera más eficiente los turnos en terminales de contenedores y realizar reservas con múltiples navieras sin problemas. La mejora en la eficiencia operativa y la reducción de tiempos de respuesta nos han permitido ofrecer un servicio más ágil y confiable a nuestros clientes. Estamos muy contentos con el resultado.",
      },
    ],
  },
  contact: {
    title: "Contáctanos",
    description:
      "Conéctate con nosotros: analicemos a donde quieres llegar y cómo podemos ayudarte a llegar allí.",
    subtitle: "Información de contacto",
    description2:
      "Complete el formulario y nuestro equipo se comunicará con usted dentro de las 24 horas.",
    email: "info@pacificodes.com",
    phone: "+57 314 696 1991",
    phone2: "+57 313 737 4583",
    ubication: "Buenaventura, VL Colombia",
  },
  blog: {
    title: "Blog",
    description:
      "Explora Nuestro Blog: Artículos, Consejos y Novedades en el Mundo del Desarrollo de Software y la Tecnología.",
    blogs: [
      {
        id: 1,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog1",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 2,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog2",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 3,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog3",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 4,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog4",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 5,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog5",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 6,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog6",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
      {
        id: 7,
        title:
          "Porttitor pharetra, consectetur viverra est nisl a, vulputate id",
        description:
          "Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium.",
        image: "blog7",
        category: "Tecnología",
        date: "13 Sept, 2021",
      },
    ],
  },
};

export default DATA;
