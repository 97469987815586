import { useNavigate } from "react-router-dom";
import { tokio, tokioB, tokioC, tokioE, tokioL, tokioV } from "../assets";
import Contact from "../components/Contact";
import SectionWrapper from "../hoc/SectionWrapper";
import DefaultLayout from "../shared/layouts/DefaultLayout";
import BigCard from "../shared/subcomponents/BigCard";
import SubHeader from "../shared/subcomponents/SubHeader";
import { styles } from "../theme/styles";
import DATA from "../utils/data";

const ServicesPage = () => {
  const images = [tokio, tokioL, tokioC, tokioE, tokioB, tokioV];
  const navigate = useNavigate();
  return (
    <DefaultLayout>
      <SubHeader
        title="Servicios"
        description="Unamos su Visión con Nuestras Habilidades para Desarrollar
            Soluciones Personalizadas para su Negocio."
        type="type2"
      />
      <div
        className={`${styles.paddingContainer} grid grid-rows-3 gap-10 sm:grid-cols-2 md:grid-cols-2 xmd:grid-cols-2 lg:grid-cols-2`}
      >
        {DATA.services.services.map((service, index) => (
          <BigCard
            key={index}
            height="h-auto"
            backgroundColor="bg-gray hover:shadow-xl hover:shadow-primary p-10"
          >
            <div className="flex flex-col items-start justify-center gap-5">
              <div className="relative flex h-24 w-24 flex-row items-center justify-center rounded-full bg-primary bg-opacity-20 p-7">
                <img
                  src={images[service.id]}
                  className="absolute w-1/2"
                  alt={service.title}
                />
              </div>
              <h2 className="font-poppinsBold text-2xl">{service.title}</h2>
              <p className="font-poppinsLight text-lg">{service.description}</p>
              <div className="flex flex-row gap-5">
                <button
                  className="rounded-3xl bg-secondary px-5 py-3 text-white"
                  onClick={() => {
                    navigate("/contacto");
                  }}
                >
                  Conectemos
                </button>
                <button className="rounded-3xl border border-primary bg-transparent px-5 py-3 text-primary">
                  Proyectos
                </button>
              </div>
            </div>
          </BigCard>
        ))}
      </div>
      <Contact />
    </DefaultLayout>
  );
};

const ServicesPageWithTransition = SectionWrapper(ServicesPage);

export default ServicesPageWithTransition;
