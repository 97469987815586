import { styles } from "../theme/styles";
import SubHeader from "../shared/subcomponents/SubHeader";
import DATA from "../utils/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from "swiper/modules";
import "swiper/css";
import { dot, dotActive } from "../assets";
import { useState } from "react";

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener(
    "resize",
    () => setWindowWidth(window.innerWidth),
    true,
  );

  return (
    <div
      className={`flex w-full flex-col pt-14 transition-all duration-500 ease-in-out lg:pt-32`}
    >
      <div className={`${styles.paddingContainer}`}>
        <SubHeader
          description={DATA.testimonials.description}
          title={DATA.testimonials.title}
        />
        <div className="flex w-full">
          <div className="w-full overflow-hidden rounded-[45px] bg-black py-20 transition-all duration-500  ease-in-out md:bg-secondary xmd:bg-secondary lg:bg-secondary xl:bg-secondary">
            <Swiper
              modules={[Controller]}
              spaceBetween={20}
              slidesPerView={windowWidth <= 900 ? 1 : 1.1}
              centeredSlides={true}
              className="flex flex-col items-center justify-between gap-10 px-5 transition-all duration-500 ease-in-out sm:gap-12 md:gap-16 xmd:gap-20 lg:gap-24"
              onSlideChange={(value) => setActiveIndex(value.activeIndex)}
            >
              {DATA.testimonials.testimonials.map((item) => (
                <SwiperSlide key={item.id}>
                  <div
                    key={item.id}
                    className="flex h-full flex-col items-center justify-between rounded-[45px] rounded-bl-none border border-primary bg-black transition-all duration-500 ease-in-out md:border-black xmd:border-black lg:flex-row lg:border-black"
                  >
                    <div className="flex flex-col justify-between px-6 py-5 sm:px-8 md:px-10 xmd:px-12 lg:px-14">
                      <p className="font-poppinsLight text-xs text-white sm:text-sm md:text-lg xmd:text-lg lg:text-lg">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="lg:ml-10">
                    <h1 className="mt-5 font-poppinsLight text-lg text-primary">
                      {item.client}
                    </h1>
                    <span className="text-white">{item.position}</span>
                  </div>
                </SwiperSlide>
              ))}
              <div className="flex flex-row justify-center gap-5">
                {DATA.testimonials.testimonials.map((_, index) => (
                  <div key={index}>
                    {activeIndex === index ? (
                      <img src={dotActive} alt="dotActive" />
                    ) : (
                      <img src={dot} alt="dot" />
                    )}
                  </div>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
