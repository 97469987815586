import "../../App.css";
import RoundButton from "./RoundButton";

interface CardProps {
  backgroundColor?: string;
  title: string;
  image: string;
  action?: () => void;
}

const Card = ({
  backgroundColor = "bg-gray",
  title,
  image,
  action,
}: CardProps) => {
  return (
    <div
      className={`card-borders flex w-full flex-col justify-between p-12 lg:flex-row  ${backgroundColor}`}
    >
      <div className="mb-4 flex w-4/5 flex-row justify-between lg:h-full lg:w-1/2 lg:flex-col">
        <h1
          className={`rounded-lg px-2 font-calSans text-2xl text-black lg:text-3xl ${backgroundColor === "bg-primary" || backgroundColor === "bg-secondary" ? "bg-white" : "bg-primary"}`}
        >
          {title}
        </h1>
        <div className="hidden flex-row items-center lg:flex">
          <RoundButton
            action={action!}
            backgroundColor={
              backgroundColor === "bg-secondary" ? "bg-white" : "bg-secondary"
            }
          />

          <a
            href="/servicios"
            className={`ml-4 font-poppinsLight text-xl ${backgroundColor === "bg-secondary" ? "text-white" : backgroundColor === "bg-primary" ? "text-secondary" : "text-black"}`}
          >
            Ver más
          </a>
        </div>
      </div>
      <div className="flex flex-row items-end justify-between">
        <div className="lg:hidden">
          <RoundButton
            action={() => action}
            backgroundColor={
              backgroundColor === "bg-secondary" ? "bg-white" : "bg-secondary"
            }
          />
        </div>
        <img src={image} className="w-2/3 lg:w-full" alt={title} />
      </div>
    </div>
  );
};

export default Card;
