import { styles } from "../theme/styles";
import BigCard from "../shared/subcomponents/BigCard";
import SubHeader from "../shared/subcomponents/SubHeader";
import { arrowPrimary } from "../assets";
import DATA from "../utils/data";

const Cases = () => {
  return (
    <div id="cases" className={`flex w-full flex-col  pt-14 lg:pt-32`}>
      <div className={`${styles.paddingContainer}`}>
        <SubHeader
          description={DATA.cases.description}
          title={DATA.cases.title}
        />
        <div className="hidden w-full md:flex lg:flex">
          <BigCard backgroundColor="bg-secondary p-12">
            <div className="grid grid-cols-3 grid-rows-1 gap-10">
              {DATA.cases.cases.map((item, index) => (
                <div
                  key={item.id}
                  className={`flex flex-col justify-between ${index > 0 ? "border-l-2 border-white sm:pl-2 md:pl-4 lg:pl-8" : ""}`}
                >
                  <p className="font-poppinsLight text-white sm:text-sm md:text-sm xmd:text-base lg:text-lg">
                    {item.description.length > 200
                      ? item.description.substring(0, 200) + "..."
                      : item.description}
                  </p>
                  <div className="flex h-9 flex-row items-end gap-3">
                    <h1 className="text-md mt-4 font-poppinsLight text-primary">
                      Ver más
                    </h1>
                    <img
                      src={arrowPrimary}
                      alt="ver mas"
                      className="mb-1 h-4 w-3"
                    />
                  </div>
                </div>
              ))}
            </div>
          </BigCard>
        </div>
      </div>
      <div className="flex w-full scroll-m-0 flex-row gap-5 overflow-x-scroll px-5 no-scrollbar md:hidden lg:hidden">
        {DATA.cases.cases.map((item) => (
          <BigCard key={item.id} backgroundColor="bg-secondary">
            <div className="flex w-64 flex-col justify-between">
              <p className="font-poppinsLight text-white">{item.description}</p>
              <div className="mt-5 flex h-9 flex-row items-end gap-3">
                <h1 className="text-md mt-4 font-poppinsLight text-primary">
                  Ver más
                </h1>
                <img
                  src={arrowPrimary}
                  alt="ver mas"
                  className="mb-1 h-4 w-3"
                />
              </div>
            </div>
          </BigCard>
        ))}
      </div>
    </div>
  );
};

export default Cases;
