interface IPrincipalButton {
  handleAction?: () => void;
  width?: string;
  label: string;
  classPadding: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
}

const PrincipalButton = ({
  handleAction,
  label,
  classPadding,
  width = "w-32",
  disabled,
  type = "button",
  children,
}: IPrincipalButton) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleAction}
      className={`${classPadding} justify-center rounded-xl bg-secondary text-white hover:shadow-sm hover:shadow-primary ${width} ${disabled && "cursor-not-allowed"}`}
    >
      {label}
      {children}
    </button>
  );
};

export default PrincipalButton;
