import { animate, useMotionValue, motion } from "framer-motion";
import { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { amazon, dribble, hubspot, netflix, notion, zoom } from "../../assets";

const Technologies = [
  {
    image: amazon,
  },
  {
    image: dribble,
  },
  {
    image: hubspot,
  },
  {
    image: netflix,
  },
  {
    image: notion,
  },
  {
    image: zoom,
  },
];

const Carousel = () => {
  const [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    let controls;
    const finalPosition = -width / 2 - 6;

    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: 45,
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: "linear",
        duration: 45,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }

    return controls?.stop;
  }, [rerender, xTranslation, width]);

  return (
    <motion.div
      ref={ref}
      style={{ x: xTranslation }}
      className="flex flex-row items-center gap-4"
    >
      {[...Technologies, ...Technologies].map((tech, index) => (
        <motion.div
          className="relative flex h-[200px] min-w-[200px] items-center justify-center overflow-hidden"
          key={index}
        >
          <img
            className="h-10 object-scale-down"
            src={tech.image}
            alt={tech.image}
          />
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Carousel;
