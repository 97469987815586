import { styles } from "../theme/styles";
import Card from "../shared/subcomponents/Card";
import {
  tokioR,
  tokio,
  tokioB,
  tokioC,
  tokioE,
  tokioV,
  tokioL,
} from "../assets";
import BigCard from "../shared/subcomponents/BigCard";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";
import SubHeader from "../shared/subcomponents/SubHeader";
import DATA from "../utils/data";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const images = [tokio, tokioL, tokioC, tokioE, tokioB, tokioV];
  return (
    <div
      className={`flex w-full flex-col pt-14 lg:pt-32 ${styles.paddingContainer}`}
    >
      <SubHeader
        description={DATA.services.description}
        title={DATA.services.title}
      />
      <div className="grid grid-rows-3 gap-10 sm:grid-cols-2 md:grid-cols-2 xmd:grid-cols-2 lg:grid-cols-2">
        {DATA.services.services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            image={images[service.id]}
            backgroundColor={service.backgroundColor}
            action={() => {
              navigate("/servicios");
            }}
          />
        ))}
      </div>
      <div className="flex w-full pt-24">
        <BigCard backgroundColor="bg-gray p-12">
          <div className="mb-4 flex w-full flex-col px-2 lg:h-full lg:w-2/3">
            <h1 className="mb-6 font-calSans text-2xl text-black lg:text-3xl">
              Hagamos que suceda
            </h1>
            <p className="mb-6">
              Contáctanos hoy para obtener más información sobre cómo nuestros
              servicios pueden ayudar a que tu negocio crezca y tenga éxito en
              línea.
            </p>
            <div className="mt-6">
              <PrincipalButton
                handleAction={() => {
                  navigate("/contacto");
                }}
                label="Hablemos"
                classPadding="py-3 px-8"
              />
            </div>
          </div>
          <div className="hidden flex-row items-end justify-between lg:flex">
            <img
              src={tokioR}
              className="w-2/3 lg:w-full"
              alt="contacto-pacificode"
            />
          </div>
        </BigCard>
      </div>
    </div>
  );
};

export default Services;
