import { email, face, instagram, linked, phone, twiter, ubi } from "../assets";
import SectionWrapper from "../hoc/SectionWrapper";
import DefaultLayout from "../shared/layouts/DefaultLayout";
import PrincipalButton from "../shared/subcomponents/PrincipalButton";
import SubHeader from "../shared/subcomponents/SubHeader";
import { styles } from "../theme/styles";
import DATA from "../utils/data";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SendNotificationContact } from "../utils/actions.notifications";

interface IContactData {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  message: string;
  date: string;
  services: string[];
}

const ContactPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IContactData>();

  const onSubmit = async (data: IContactData) => {
    try {
      setLoading(true);
      const now = new Date();
      data.date = now.toISOString();
      data.name = data.name + " " + data.lastname;
      const res = await SendNotificationContact(data);
      if (res.status === 200) {
        toast.success("Excelente! Mensaje enviado correctamente");
      }
      reset();
      setLoading(false);
    } catch (error) {
      toast.error("Error al enviar el mensaje");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <SubHeader
        title="Hablemos"
        description={DATA.contact.description}
        type="type2"
      />
      <div className={`${styles.paddingContainer} h-auto`}>
        <div className="flex h-auto w-full flex-col-reverse items-center  justify-between rounded-[25px] bg-white  p-0 shadow-lg xmd:flex-row lg:flex-row">
          <div className="rounded-b-[25px] bg-secondary sm:h-auto sm:w-full md:flex md:h-auto md:w-full xmd:flex xmd:h-screen xmd:w-2/5 xmd:rounded-l-[25px] xmd:rounded-br-none lg:flex lg:h-screen lg:w-2/5 lg:rounded-l-[25px] lg:rounded-br-none">
            <div className="flex flex-col items-start justify-start px-3 py-8">
              <h3 className="mb-5 font-poppinsSemiBold text-xl text-white">
                {DATA.contact.subtitle}
              </h3>
              <span className="mb-10 font-poppins text-white">
                {DATA.contact.description2}
              </span>
              <div className="flex flex-col gap-5 sm:grid sm:grid-cols-2 sm:flex-row md:flex-row xmd:flex xmd:flex-col">
                <div className="flex flex-row gap-3">
                  <img src={ubi} alt="ubicacion" />
                  <span className="font-poppins text-white">
                    {DATA.contact.ubication}
                  </span>
                </div>
                <div className="flex flex-row gap-3">
                  <img src={phone} alt="phone" />
                  <span className="font-poppins text-white">
                    {DATA.contact.phone}
                  </span>
                </div>
                <div className="flex flex-row gap-3">
                  <img src={phone} alt="phone" />
                  <span className="font-poppins text-white">
                    {DATA.contact.phone2}
                  </span>
                </div>
                <div className="flex flex-row gap-3">
                  <img src={email} alt="email" />
                  <a
                    href={`mailto:${DATA.contact.email}`}
                    className="object-scale-down font-poppins text-white underline"
                    target="_blank"
                  >
                    {DATA.contact.email}
                  </a>
                </div>
              </div>

              <div className="mt-5 flex w-full flex-row justify-between sm:gap-5 xmd:flex-col lg:flex-col">
                <a
                  href={DATA.social.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-row items-center gap-1 sm:gap-3"
                >
                  <img src={face} alt="Facebook" />
                  <span className="text-[10px] text-white sm:text-sm">
                    Facebook
                  </span>
                </a>
                <a
                  href={DATA.social.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-row items-center gap-1 sm:gap-3"
                >
                  <img src={instagram} alt="instagram" />
                  <span className="text-[10px] text-white sm:text-sm">
                    Instagram
                  </span>
                </a>
                <a
                  href={DATA.social.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-row items-center gap-1 sm:gap-3"
                >
                  <img src={linked} alt="linked" />
                  <span className="text-[10px] text-white sm:text-sm">
                    Linkedin
                  </span>
                </a>
                <a
                  href={DATA.social.twitter}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-row items-center gap-1 sm:gap-3"
                >
                  <img src={twiter} alt="twiter" />
                  <span className="text-[10px] text-white sm:text-sm">
                    Twitter
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="flex h-full w-full px-5 py-10 md:p-10 xmd:p-10 lg:p-10">
            <form
              className="flex w-full flex-col items-end gap-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex w-full flex-col items-center justify-center gap-5">
                <h1 className="font-poppinsSemiBold text-xl">
                  Formulario de contacto
                </h1>
                <span className="flex text-center font-poppins text-secondary md:hidden xmd:hidden lg:hidden">
                  {DATA.contact.description2}
                </span>
              </div>
              <div className="flex w-full flex-col justify-between gap-5 xmd:flex-row xmd:gap-10 lg:flex-row lg:gap-10">
                <div className="flex w-full flex-col">
                  <label htmlFor="name" className="text-secondary">
                    Nombres*
                  </label>
                  <input
                    type="text"
                    {...register("name", { required: true })}
                    className={`bg-gray-100 border-gray-100 my-2 w-full rounded-2xl border p-2 focus:border-primary focus:outline-none ${errors.name && "border-red-500"}`}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <label htmlFor="name" className="text-secondary">
                    Apellidos*
                  </label>
                  <input
                    type="text"
                    {...register("lastname", { required: true })}
                    className={`bg-gray-100 border-gray-100 my-2 w-full rounded-2xl border p-2 focus:border-primary focus:outline-none ${errors.lastname && "border-red-500"}`}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col justify-between gap-5 xmd:flex-row xmd:gap-10 lg:flex-row lg:gap-10">
                <div className="flex w-full flex-col">
                  <label htmlFor="name" className="text-secondary">
                    Correo Electrónico*
                  </label>
                  <input
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    className={`bg-gray-100 border-gray-100 my-2 w-full rounded-2xl border p-2 focus:border-primary focus:outline-none ${errors.email && "border-red-500"}`}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <label htmlFor="name" className="text-secondary">
                    Teléfono*
                  </label>
                  <input
                    type="text"
                    {...register("phone", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                      pattern: /^[0-9]*$/,
                    })}
                    className={`bg-gray-100 border-gray-100 my-2 w-full rounded-2xl border p-2 focus:border-primary focus:outline-none ${errors.phone && "border-red-500"}`}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between gap-8">
                <h3>Que servicio necesitas?</h3>
                <div className="grid grid-cols-2 grid-rows-3 gap-10 xmd:grid-cols-3 xmd:grid-rows-2 lg:grid-cols-3 lg:grid-rows-2">
                  {DATA.services.services.map((service, index) => (
                    <div className="flex flex-row justify-around" key={index}>
                      <label htmlFor="name" className="text-secondary">
                        {service.title}
                      </label>
                      <input
                        type="checkbox"
                        {...register("services", { required: true })}
                        value={service.title}
                        className="bg-gray-100 border-gray-100 w-[20px] rounded-2xl border"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex w-full flex-col">
                <label htmlFor="message" className="text-gray-500">
                  Mensaje*
                </label>
                <input
                  type="text"
                  {...register("message", { required: true })}
                  placeholder="Escribe tu mensaje..."
                  className={`bg-gray-100 border-gray-100 my-4 w-full border-b p-2 focus:border-primary focus:outline-none ${errors.message && "border-red-500"}`}
                />
              </div>
              <PrincipalButton
                type="submit"
                classPadding="py-3 p-6"
                label={loading ? "Enviando..." : "Enviar mensaje"}
                width="w-auto"
                disabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

const ContactPageWithTransition = SectionWrapper(ContactPage);

export default ContactPageWithTransition;
